<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'添加卡项':$route.query.type == 2?"编辑":"编辑卡项" }}</span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <div class="addleft">
        <div class="leftbox">
          <div
            class="onetxt"
            @click="goStep(1)"
            :class="step===1?'active':''"
          >1</div>
          <div
            class="base"
            @click="goStep(1)"
            :class="step===1?'active1':''"
          >基本设置</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(2)"
            :class="step===2?'active':''"
          >2</div>
          <div
            class="base"
            @click="goStep(2)"
            :class="step===2?'active1':''"
          >卡项内容</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(3)"
            :class="step===3?'active':''"
          >3</div>
          <div
            class="base"
            @click="goStep(3)"
            :class="step===3?'active1':''"
          >其他设置</div>
        </div>
      </div>
      <div class="addright">
        <!-- 第一步 -->
        <div
          class="one"
          v-if="step===1"
        >
          <div class="zengpinstep">
            <h2
              class="twoh2"
              style="margin-bottom:13px"
            >
              <span class="tpointer"></span>
              <span class="ttxt">基本设置</span>
            </h2>
            <a-form
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
              labelAlign="left"
            >
              <!-- 来源 -->
              <a-form-item
                label="来源"
                style="margin-bottom:17px;"
              >
                总部创建
              </a-form-item>
              <!-- 产品名称 -->
              <a-form-item
                label="卡项名称"
                required
              >
                <a-input
                  v-model="goods_name"
                  allowClear
                  :maxLength="20"
                  class="ant-input_1 wid-488"
                  placeholder="输入卡项名称，20字以内"
                />
              </a-form-item>
              <!-- 商品码 -->
              <a-form-item
                required
                label="商品码"
              >
                <a-input
                  allowClear
                  class="ant-input_1 wid-488"
                  v-model="form.goods_bn"
                  :maxLength="20"
                  oninput="value=value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, '')"
                  placeholder="名称首字母+4位随机数"
                />
              </a-form-item>
              <!-- 标准单价 -->
              <a-form-item
                label="标准单价"
                required
              >
                <a-input
                  allowClear
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                  class="ant-input_1"
                  prefix="￥"
                  v-model="form.goods_attr_val[0].product_price"
                  style="width:154px;"
                  :maxLength="20"
                  placeholder="标准单价"
                />
              </a-form-item>
              <!-- 有效期 -->
              <a-form-item
                label="有效期"
                required
                class="samerow1"
              >
                <!-- <span>{{form.times}}</span> -->
                <div class=" flex align-item-center">
                  <!-- <div
                    style="width:206px;"
                    class="ant-input_data  flex align-item-center"
                  >
                    <a-input suffix="年" :maxLength="20" v-model="form.validity_year"  />
                    <span class="line"></span>
                    <a-input suffix="月" :maxLength="20" v-model="form.validity_month" />
                    <span class="line" ></span>
                    <a-input suffix="日" :maxLength="20"  v-model="form.validity_day" />
                  </div>
                  <div class="basic_name mgl-32">
                    <a-radio-group
                      class="radio_nomal1"
                      name="radioGroup"
                      :default-value="2"
                      v-model="form.validity_type"
                    >
                      <a-radio :value="2">
                        永久有效
                      </a-radio>
                    </a-radio-group>
                  </div> -->
                  <div
                    class="checkqystatus flex align-item-center"
                    style="width:358px;"
                  >
                    <span
                      class="cqt1"
                      style="width:89px;"
                      @click="form.validity_type=365"
                      :class="form.validity_type==365? 'cqt1A':''"
                    >年</span>
                    <span
                      class="cqt1"
                      style="width:89px;"
                      @click="form.validity_type=30"
                      :class="form.validity_type==30? 'cqt1A':''"
                    >月</span>
                    <span
                      class="cqt1"
                      style="width:89px;"
                      @click="form.validity_type=1"
                      :class="form.validity_type==1? 'cqt1A':''"
                    >日</span>
                    <span
                      class="cqt1"
                      style="width:89px;"
                      @click="form.validity_type=2"
                      :class="form.validity_type==2? 'cqt1A':''"
                    >永久有效</span>
                  </div>
                  <a-input
                    oninput="value=value.replace(/[^\d]/g,'')"
                    v-if="form.validity_type!=2"
                    :suffix="form.validity_type==365?'年':form.validity_type==30?'月':form.validity_type==1?'日':''"
                    v-model="form.validity"
                    style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                    :maxLength="20"
                    class="validity ant-input_1"
                  />
                </div>
              </a-form-item>
              <!-- 类别 -->
              <div class="samerow flex">
                <a-form-item
                  label="品牌"
                  class="samerow1"
                >
                  <div
                    class=" flex align-item-center"
                    style=""
                  >
                    <div class="flex align-item-center">
                      <a-select
                        placeholder="选择品牌"
                        v-model="form.goods_brand"
                        allowClear
                        style="width: 268px"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                      >
                        <a-select-option
                          v-for="item in brandsList"
                          :key="item.brand_id"
                          :value="item.brand_id"
                        >
                          {{ item.brand_name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <a-form-item
                    label="财务分类"
                    required
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas"
                        class="styledatas"
                      >
                        <a-cascader
                          v-model="form.finance_category_id"
                          :options="treeData2"
                          allowClear
                          placeholder="财务分类"
                          style="width: 260px"
                          :fieldNames="selectreeObj2"
                          :getPopupContainer="getPopupContainer"
                        />
                      </span>
                    </template>
                  </a-form-item>
                </a-form-item>
              </div>
              <!-- 品牌 -->
              <div class="samerow flex">
                <!-- 合作项目 -->
                <a-form-item
                  label="合作项目"
                  required
                  class="samerow1"
                >
                  <div class=" flex align-item-center mgr-6">
                    <div class="checkqystatus mgr-82 flex align-item-center">
                      <span
                        class="cqt1"
                        @click="form.goods_type_hz=0"
                        :class="form.goods_type_hz==0? 'cqt1A':''"
                      >否</span>
                      <span
                        class="cqt1"
                        @click="form.goods_type_hz=1"
                        :class="form.goods_type_hz==1? 'cqt1A':''"
                      >是</span>
                    </div>
                  </div>
                  <a-form-item
                    label="门店分类"
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas2"
                        class="styledatas2"
                      >
                        <a-cascader
                          v-model="form.goods_category"
                          :options="treeData"
                          allowClear
                          placeholder="门店分类"
                          style="min-width: 260px;"
                          :fieldNames="selectreeObj"
                          :getPopupContainer="getPopupContainer2"
                        />
                      </span>
                    </template>
                  </a-form-item>
                </a-form-item>
              </div>
              <div class="samerow flex">
                <a-form-item
                  label="商城分类"
                  class="samerow1"
                >
                  <template>
                    <span
                      style="width:auto;position: relative;width:260px;"
                      id="styledatas3"
                      class="styledatas3"
                    >
                      <a-cascader
                        v-model="form.store_category_id"
                        :options="treeData3"
                        allowClear
                        placeholder="商城分类"
                        style="min-width: 268px;"
                        :fieldNames="selectreeObj3"
                        :getPopupContainer="getPopupContainer3"
                      />
                    </span>

                  </template>
                </a-form-item>

              </div>
              <!-- 兑付次数 -->
              <div class="samerow flex">

                <a-form-item
                  label="兑付次数"
                  required
                  class="samerow1"
                >
                  <a-tooltip>
                    <template slot="title">
                      购买卡项后，可兑付的总次数
                    </template>
                    <a-icon
                      type="info-circle"
                      style="font-size:14px;position: absolute; left: 81px; top: 12px;"
                    />
                  </a-tooltip>
                  <div
                    class=" flex align-item-center mgr-10"
                    style="min-width:260px;"
                  >
                    <div
                      class="checkqystatus flex align-item-center"
                      style="width:180px;"
                    >
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_use_type=0"
                        :class="form.proposal_use_type==0? 'cqt1A':''"
                      >不限</span>
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_use_type=1"
                        :class="form.proposal_use_type==1? 'cqt1A':''"
                      >限制</span>
                    </div>
                    <a-input
                      class="validity ant-input_1"
                      v-model="form.proposal_use_count"
                      style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-if="form.proposal_use_type==1"
                      suffix="次"
                    />
                  </div>
                  <!-- 实耗成本 -->
                  <a-form-item
                    label="实耗成本"
                    v-if="form.proposal_use_type==0"
                    required
                    class="samerow2"
                    style="margin-bottom:0px;position:relative;"
                  >
                    <a-tooltip>
                      <template slot="title">
                        兑付次数无上限时，需要设定实耗成本次数，用于报表统计当前卡项的实耗金额
                      </template>
                      <a-icon
                        type="info-circle"
                        style="font-size:14px;position: absolute; right: 206px;top: 13px;"
                      />
                    </a-tooltip>
                    <a-input
                      class="validity ant-input_1"
                      v-model="form.proposal_count_price"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:180px;height:32px;line-height:32px;"
                      suffix="次"
                    />
                  </a-form-item>
                </a-form-item>

              </div>
              <!-- 激活方式 -->
              <a-form-item
                label="激活方式"
                required
                class=" "
              >
                <div
                  class="checkqystatus flex align-item-center"
                  style="width:230px;"
                >
                  <span
                    class="cqt1"
                    style="width:115px;"
                    @click="form.proposal_charging_type=1"
                    :class="form.proposal_charging_type==1? 'cqt1A':''"
                  >购买之日激活</span>
                  <span
                    class="cqt1"
                    style="width:115px;"
                    @click="form.proposal_charging_type=2"
                    :class="form.proposal_charging_type==2? 'cqt1A':''"
                  >首次兑付激活</span>
                </div>
              </a-form-item>
              <!-- 每月兑付 -->
              <div class="samerow flex">

                <a-form-item
                  label="每月兑付"
                  required
                  class="samerow1"
                >
                  <a-tooltip>
                    <template slot="title">
                      购买卡项后，每月允许兑付次数上限
                    </template>
                    <a-icon
                      type="info-circle"
                      style="font-size:14px;position: absolute; left: 81px; top: 12px;"
                    />
                  </a-tooltip>
                  <div
                    class=" flex align-item-center  mgr-10"
                    style="min-width:330px;"
                  >
                    <div
                      class="checkqystatus    flex align-item-center"
                      style="width:180px;"
                    >
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_month_use_type=0"
                        :class="form.proposal_month_use_type==0? 'cqt1A':''"
                      >不限</span>
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_month_use_type=1"
                        :class="form.proposal_month_use_type==1? 'cqt1A':''"
                      >限制</span>
                    </div>
                    <a-input
                      class="validity ant-input_1"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model="form.proposal_month_use_count"
                      style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                      v-if="form.proposal_month_use_type==1"
                      suffix="次"
                    />
                  </div>
                  <!-- 实耗成本 -->
                  <a-form-item
                    label="每周兑付"
                    required
                    class="samerow2 samerow3"
                    style="margin-bottom:0px;position:relative;"
                  >

                    <div class=" flex align-item-center po-relative">
                      <div
                        class="flex align-item-center mgr-10"
                        style="width:298px; "
                      >
                        <a-tooltip>
                          <template slot="title">
                            购买卡项后，每周允许兑付次数上限
                          </template>
                          <a-icon
                            type="info-circle"
                            style="font-size:14px;position: absolute; left: -44px;top: 10px;"
                          />
                        </a-tooltip>
                        <div
                          class="checkqystatus flex align-item-center"
                          style="width:180px;"
                        >
                          <span
                            class="cqt1"
                            style="width:90px"
                            @click="form.proposal_week_use_type=0"
                            :class="form.proposal_week_use_type==0? 'cqt1A':''"
                          >不限</span>
                          <span
                            class="cqt1"
                            style="width:90px"
                            @click="form.proposal_week_use_type=1"
                            :class="form.proposal_week_use_type==1? 'cqt1A':''"
                          >限制</span>
                        </div>
                        <span
                          style="width:118px;height:32px;"
                          class="flex align-item-center"
                        >
                          <a-input
                            class="validity ant-input_1"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            v-model="form.proposal_week_use_count"
                            style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                            v-if="form.proposal_week_use_type==1"
                            suffix="次"
                          />
                        </span>

                      </div>
                    </div>

                  </a-form-item>
                </a-form-item>

              </div>
              <!-- 单次兑付 -->
              <div class="samerow flex">

                <a-form-item
                  label="单次兑付"
                  required
                  class="samerow1"
                >
                  <a-tooltip>
                    <template slot="title">
                      购买卡项后，每次兑付时可以消耗的项目数量上限
                    </template>
                    <a-icon
                      type="info-circle"
                      style="font-size:14px;position: absolute; left: 81px; top: 12px;"
                    />
                  </a-tooltip>
                  <div
                    class=" flex align-item-center  mgr-10"
                    style="min-width:330px;"
                  >
                    <div
                      class="checkqystatus  flex align-item-center"
                      style="width:180px;"
                    >
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_every_sku_type=0"
                        :class="form.proposal_every_sku_type==0? 'cqt1A':''"
                      >不限</span>
                      <span
                        class="cqt1"
                        style="width:90px"
                        @click="form.proposal_every_sku_type=1"
                        :class="form.proposal_every_sku_type==1? 'cqt1A':''"
                      >限制</span>
                    </div>
                    <a-input
                      class="validity ant-input_1"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model="form.proposal_every_sku_count"
                      style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                      v-if="form.proposal_every_sku_type==1"
                      suffix="次"
                    />
                  </div>
                  <!-- 限购 -->
                  <a-form-item
                    label="限购"
                    required
                    class="samerow2 samerow3"
                    style="margin-bottom:0px;position:relative;"
                  >
                    <div class=" flex align-item-center po-relative">
                      <div
                        class="flex align-item-center"
                        style="width:298px; "
                      >
                        <a-tooltip>
                          <template slot="title">
                            同一顾客，同一个卡项允许重复购买的数量上限
                          </template>
                          <a-icon
                            type="info-circle"
                            style="font-size:14px;position: absolute; left: -72px;top: 10px;"
                          />
                        </a-tooltip>
                        <div class=" flex align-item-center po-relative">
                          <div
                            class="checkqystatus flex align-item-center"
                            style="width:180px;"
                          >
                            <span
                              class="cqt1"
                              style="width:90px"
                              @click="form.proposal_pay_type=0"
                              :class="form.proposal_pay_type==0? 'cqt1A':''"
                            >不限</span>
                            <span
                              class="cqt1"
                              style="width:90px"
                              @click="form.proposal_pay_type=1"
                              :class="form.proposal_pay_type==1? 'cqt1A':''"
                            >限购</span>
                          </div>
                          <span
                            style="width:118px;height:32px;"
                            class="flex align-item-center"
                          >
                            <a-input
                              allowClear
                              class="validity ant-input_1"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="form.proposal_pay_count"
                              style="width:118px;height:32px;line-height:32px;margin-left:12px;"
                              v-if="form.proposal_pay_type==1"
                            />
                          </span>

                        </div>
                      </div>
                    </div>

                  </a-form-item>
                </a-form-item>

              </div>
              <!-- 赠送 -->
              <a-form-item
                label="赠送"
                required
              >
                <div
                  class="checkqystatus flex align-item-center"
                  style="width:214px;"
                >
                  <span
                    class="cqt1"
                    style="width:106px"
                    @click="form.is_give=1"
                    :class="form.is_give==1? 'cqt1A':''"
                  >允许赠送</span>
                  <span
                    class="cqt1"
                    style="width:106px"
                    @click="form.is_give=0"
                    :class="form.is_give==0? 'cqt1A':''"
                  >不允许赠送</span>
                </div>
              </a-form-item>
              <!-- 状态 -->
              <div class="samerow flex">
                <a-form-item
                  label="状态"
                  required
                  class="samerow1"
                >
                  <div class="checkqystatus flex align-item-center">
                    <span
                      class="cqt1"
                      @click="form.is_show=1"
                      :class="form.is_show==1? 'cqt1A':''"
                    >在售</span>
                    <span
                      class="cqt1"
                      @click="form.is_show=0"
                      :class="form.is_show==0? 'cqt1A':''"
                    >停售</span>
                  </div>

                  <!-- <a-form-item
                    label="销售时间"
                    required
                    class="samerow2"
                    style="margin-bottom:0px"
                    v-if="form.is_show==1"
                  >

                    <span
                      style="min-width: 364px;display:inline-block;"
                      class="flex align-item-center"
                    >
                      <a-radio-group
                        name="radioGroup"
                        class="nomal_radio"
                        v-model="form.goods_shou_type"
                      >
                        <a-radio :value="1">
                          长期
                        </a-radio>
                        <a-radio :value="2">
                          截止
                        </a-radio>
                      </a-radio-group> 
                      <a-date-picker
                        v-if="form.goods_shou_type==2"
                        v-model="form.goods_shou_end_time"
                        :class="form.goods_shou_type==2?'jzpicker1':'jzpicker'"
                        @change="onChangeTimes"
                        @openChange="isactive = !isactive"
                        format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        :show-time="{}"
                      >
                        <i
                          slot="suffixIcon"
                          class="meiye-icon meiye-rili-moren"
                          :class="{ 't-c-g': isactive }"
                          style="font-size:12px;color: @baseColor40;background: @baseColor41;margin-top: -5px;"
                        ></i>
                      </a-date-picker>
                    </span>
                  </a-form-item> -->
                </a-form-item>
              </div>
              <a-form-item
                label=" "
                style="margin-top:15px;"
              >
                <a-button
                  class="stepbackbtn1 bigheightbn wid-80"
                  type="primary"
                  @click="onStep(2)"
                >下一步</a-button>
                <a-button
                  class="mgl-20 stepbackbtn bigheightbn wid-80"
                  @click="cancel(1)"
                >取消</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!-- 第二步 -->
        <div
          class="two"
          v-if="step===2"
        >
          <div class="zengpinstep">
            <h2 class="twoh2 mgb-24">
              <span class="tpointer"></span>
              <span class="ttxt">卡项内容</span>
            </h2>
            <div class="mgt-24">
              <a-row class="filter-wrap">
                <div
                  class="flex"
                  style="margin-right:19px;margin-left: 0px;height: 32px;padding-right: 0px; text-align: center;align-items:center;justify-content: space-between;"
                >
                  <span
                    class="pointer "
                    style="display: flex;align-items: center;"
                  >
                    <span
                      @click="changeMenuplan({key:'1'})"
                      class="table-btn-clk-plan"
                      style="padding-right:12px;"
                    >
                      <i
                        class="meiye-icon meiye-routeicon-add-copy"
                        style="color:#fff;font-size:12px;margin-right:0px;margin-left:16px"
                      ></i>
                      <span> 添加产品/服务</span>
                    </span>
                    <div class="table-btn-clk-plan table-btn-clk-plan3">
                      <span class="line table-btn-clk-plan-line"></span>
                    </div>
                    <a-dropdown
                      placement="bottomRight"
                      :overlayStyle='{"width":"173px" }'
                    >
                      <span
                        style="width:38px;"
                        class="table-btn-clk-plan table-btn-clk-plan2"
                      >
                        <i
                          class="meiye-icon meiye-moren1 pointer "
                          style="color:#fff;font-size:19px;"
                        ></i>
                      </span>
                      <a-menu
                        slot="overlay"
                        @click="changeMenuplan"
                      >
                        <!-- <a-menu-item key="1">
                          添加产品/服务
                        </a-menu-item> -->
                        <a-menu-item key="2">
                          添加组
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </span>
                  <span
                    class="flex align-item-center"
                    style="font-size: 14px;font-weight: 400;color: rgba(45,56,53,0.8);"
                  >
                    消耗总数 {{ isGoodsShows?'':'('+totalConsumption+')' }} ：{{isGoodsShows?'不限': '限选'}} <a-switch
                      class="mgr-12 mgl-12"
                      v-model="isGoodsShows"
                    />
                    <a-tooltip
                      placement="topLeft"
                      overlayClassName="spec_tooltip"
                    >
                      <template slot="title">
                        未完成选中月份所有收银流水分配,不可生成该月分红订单
                      </template>
                      <a-icon
                        :style="{ color: '#819190' }"
                        type="info-circle"
                      />
                    </a-tooltip>
                  </span>
                </div>

              </a-row>
              <div class="guigeTablebox">
                <a-table
                  class="guigeTable  guigeTableSpec"
                  :scroll="{y:$utils.getfullHeight(410)}"
                  :pagination="false"
                  :rowKey="(record, index) => {return record.product_id}"
                  :loading="table.loading"
                  :columns="plan_add.columns"
                  :data-source="table.tableData"
                  style="margin-top:20px;"
                  :rowClassName="getRowClassname"
                >
                  <template
                    slot="_0"
                    slot-scope="action, record"
                  >
                    <span class="guigeTableSpec_th"> {{ record.isGroup }}</span>
                  </template>
                  <!-- 卡项名称 -->
                  <template
                    slot="_1"
                    slot-scope="action, record"
                  >
                    <a-input
                      allowClear
                      class=" ant-input_1"
                      v-if="record.isEditor"
                      v-model="record.goods_name"
                    ></a-input>
                    <a-tooltip
                      v-else
                      placement="topLeft"
                    >
                      <template slot="title">
                        {{  record.goods_name}}
                      </template>
                      {{  record.goods_name}}
                    </a-tooltip>
                  </template>
                  <!-- 标准单价 -->
                  <template
                    slot="_2"
                    slot-scope="action, record"
                  >
                    <span v-if="!record.isEditor">{{ record.goods_price }}</span>
                  </template>
                  <!-- 类型 -->
                  <template
                    slot="_3"
                    slot-scope="action, record"
                  >
                    <span v-if="['guest','third_guest'].includes(record.goods_type)">客装</span>
                    <span v-else-if="['shop','third_shop'].includes(record.goods_type)">店装</span>
                    <span v-else-if="['service','third_service'].includes(record.goods_type)">服务</span>
                    <span v-else-if="record.goods_type=='stored_card'">储值卡</span>
                    <span v-else-if="['rights_card','third_rights_card'].includes(record.goods_type)">卡项</span>
                    <span v-else>--</span>
                  </template>
                  <!-- 最多消耗量 -->
                  <template
                    slot="_4"
                    slot-scope="action, record"
                  >
                    <span
                      v-if="!record.isEditor&&record.group_name"
                      :key="record.goods_id"
                      style="height: 32px;line-height:32px;display: inline-block;"
                    >
                      <a-switch
                        class="mgr-10 mgt-2"
                        @change="onChangeQuan"
                        checked-children="不限"
                        un-checked-children="限制"
                        v-model="record.goods_limit_status"
                      />
                      <a-input-number
                        v-if="!record.goods_limit_status"
                        class="ant-input_1"
                        @change="onChangeQuan"
                        :min="0"
                        v-model="record.goods_limit_count"
                      />
                    </span>
                    <span v-else>--</span>
                  </template>
                </a-table>
              </div>

            </div>
            <a-space
              class="pdt-20 footer-bn"
              :size="20"
            >
              <a-button
                type="primary"
                @click="onStep(3)"
                class="stepbtnnext1 bigheightbn wid-80"
              >下一步</a-button>
              <a-button
                class="stepbackbtn  bigheightbn wid-80"
                @click="step = 1"
              >上一步</a-button>
              <a-button
                class="stepbackbtn bigheightbn wid-80"
                @click="cancel(2)"
              >取消</a-button>
            </a-space>
          </div>
        </div>
        <!-- 第三步 -->
        <div
          class="three"
          v-if="step===3"
        >
          <div class="zengpinstep">
            <h2 class="twoh2 mgb-32">
              <span class="tpointer"></span>
              <span class="ttxt">其他配置</span>
            </h2>
            <div class=" ">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >轮播图 </div>
                </a-col>
                <a-col :span="22">
                  <div class=" ">
                    <upload-image
                      @getImageId="getDelImageId"
                      @delimageId="delDelimageId"
                      @getImageshow="getImageshow"
                      :multiple="5"
                      :img="form.goods_slider"
                      @draggable="handleDraggable"
                      ref="uploadCom"
                    ></upload-image>
                  </div>
                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >封面 </div>
                </a-col>
                <a-col
                  :span="22"
                  class="flex"
                >
                  <div
                    class="span_toutu"
                    style="width:164px;"
                  >
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader2"
                      :show-upload-list="false"
                      :action="baseUrl+'/upload'"
                      :headers="headers"
                      :before-upload="beforeUpload"
                      :customRequest="ffuploadFiles"
                      @change="handleChange2"
                      style=""
                    >
                      <span
                        v-if="form.goods_image"
                        class="boldimgspan"
                      >
                        <i
                          @click.stop="form.goods_image='';pic_url_loading=false"
                          class="meiye-icon meiye-close-bold meiye-close-boldimg"
                        ></i>
                        <img
                          :src="form.goods_image"
                          alt="avatar"
                          style="width:48px;height:48px;"
                        />
                      </span>

                      <div v-else>
                        <a-icon :type="pic_url_loading ? 'loading' : 'plus'" />
                      </div>
                    </a-upload>
                    <span style="font-size: 12px;font-weight: 400;color: #9EA6A4;">建议尺寸750*750px</span>
                  </div>
                  <div class="desc_intor flex">
                    <span style="margin-right:36px;display:inline-block;font-size: 14px;font-weight: 400;color: rgba(45,56,53,0.8);">描述:</span>
                    <a-textarea
                      class="ant-input_1"
                      style="width: 365px;height: 72px;resize: none;"
                      v-model="form.goods_desc"
                      placeholder="请输入不超过200字描述"
                      :maxLength="200"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </div>

                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >卡项详情 </div>
                </a-col>
                <a-col
                  :span="22"
                  class="flex"
                >
                  <shop-editor
                    class="goods_editor"
                    @transfer="getUser"
                    v-model="form.goods_content"
                  >
                  </shop-editor>
                </a-col>
              </a-row>
            </div>
            <div class="last-btns">
              <a-row class="flex">
                <a-col :span="4"></a-col>
                <a-col :span="19">
                  <a-space :size="20">
                    <a-button
                      type="primary"
                      @click="okclickmod()"
                      class="stepbtnnext1 bigheightbn wid-80"
                    >保存</a-button>
                    <a-button
                      class="stepbackbtn bigheightbn wid-80"
                      @click="step = 2"
                    >上一步</a-button>
                    <a-button
                      class="stepbackbtn bigheightbn wid-80"
                      @click="cancel(3)"
                    >取消</a-button>
                  </a-space>
                </a-col>
              </a-row>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 选择产品/服务 -->
    <a-modal
      title="选择产品/服务"
      :visible="proOrser.visible"
      @ok="submitProOrser"
      @cancel=";proOrser.visible=false;"
      :width="1398"
      class="oversizeModal oversizeModal-footer24"
    >
      <div class="limits_body">
        <!-- 复合搜索框 -->
        <div class="limits_search flex align-item-center justify-between mgb-24 mgt-24">
          <div class="checks">
            <a-radio-group
              v-model="proOrser.isproOrser"
              class="lable_radiogroup"
              style="width:240px;"
            >
              <a-radio-button
                :value="1"
                style="width:120px;text-align: center;"
              >
                服务
              </a-radio-button>
              <a-radio-button
                :value="2"
                style="width:120px;text-align: center;"
              >
                产品
              </a-radio-button>
              <!-- <a-radio-button
                :value="3"
                style="width:120px;text-align: center;"
              >
                虚拟
              </a-radio-button> -->
            </a-radio-group>
          </div>
          <ComposeInput
            :selectArr="selectArr4"
            placeholderValue="输入关键字"
            @getList="getListSearchlimits"
            :styles="typestyles2"
            class="proOrSerIpt"
          ></ComposeInput>
        </div>
        <!-- 穿梭框  transferBox-->
        <div class="">
          <div class="flex">
            <div
              class="transferPro_tip mgb-12"
              style="width:706px;"
            >
              <span class="transferPro_tip_po"></span>全部产品
              <span class="transferPro_tip_sp">共{{proOrser.isproOrser==2? proOrser.productMockData.length: proOrser.servceMockData.length}}条</span>
            </div>
            <div class="transferPro_tip">
              <span class="transferPro_tip_po"></span>已选产品
              <span class="transferPro_tip_sp">共{{proOrser.isproOrser==2? rightSelectedRowKeys.length:rightSelectedRowKeys.length  }}条</span>
            </div>
          </div>
          <div class="on_transfer ant-transfer flex">
            <div class="on_left_table">
              <a-table
                :pagination="false"
                :expandIconColumnIndex="0"
                :row-selection="rowSelection"
                :columns="proOrser.leftColumns"
                :data-source="proOrser.isproOrser==2? proOrser.productMockData:proOrser.servceMockData"
                :scroll="{y:482}"
                size="small"
                :rowKey="(record, index) => {return record.key}"
                :expandIcon="expandIcon"
              >
                <template
                  slot="_0"
                  slot-scope="action, record"
                >
                  <span
                    class=""
                    v-if="record.ischild"
                  >
                    --
                  </span>
                  <span
                    class=""
                    v-else
                  >
                    <span v-if="['guest','third_guest'].includes(record.goods_type)">客装</span>
                    <span v-else-if="['shop','third_shop'].includes(record.goods_type)">店装</span>
                    <span v-else-if="record.goods_type== 'service'">服务</span>
                    <span v-else-if="record.goods_type=='third_service'">合作服务</span>
                    <span v-else-if="record.goods_type=='stored_card'">储值卡</span>
                    <span v-else-if="['rights_card','third_rights_card'].includes(record.goods_type)">卡项</span>
                    <span v-else>--</span>
                  </span>

                </template>
                <template
                  slot="brand_name"
                  slot-scope="action, record"
                >
                  <span v-if="record.brand_name && !record.ischild">
                    {{record.brand_name}}
                  </span>
                  <span v-else>--</span>

                </template>
                <template
                  slot="goods_attr_value"
                  slot-scope="action, record"
                >
                  <span v-if="record.goods_attr_value ">
                    {{record.goods_attr_value}}
                  </span>
                  <span v-else>--</span>

                </template>
                <template
                  slot="goods_name"
                  slot-scope="action, record"
                >
                  <span v-if="!record.goods_name || record.ischild">--</span>
                  <a-tooltip v-else>
                    <template slot="title">
                      <div class=" ">
                        <p class=" "> {{record.goods_name}}</p>
                        <p class=" ">商品码： {{ record.goods_bn }}</p>
                      </div>
                    </template>
                    <div class="goodsNamebox">
                      <p class="pro_name"> {{record.goods_name}}</p>
                      <p class="pro_bn">商品码： {{ record.goods_bn }}</p>
                    </div>
                  </a-tooltip>

                </template>
                <template
                  slot="_2"
                  slot-scope="action, record"
                >
                  <span v-if="record.ischild">--</span>
                  <span v-else>{{ record.goods_name}}</span>
                </template>
                <template
                  slot="_1"
                  slot-scope="action, record"
                >
                  <span v-if="record.ischild">--</span>
                  <span v-else>
                    <span v-if="record.finance_category_name || record.goods_category_name || record.store_category_name">
                      <a-tooltip>
                        <template slot="  title">
                          {{record.finance_category_name}}
                          <span v-if="record.goods_category_name">,{{record.goods_category_name}}</span>
                          <span v-if="record.store_category_name">,{{record.store_category_name}}</span>
                        </template>
                        {{ record.finance_category_name}}
                      </a-tooltip>
                    </span>
                    <span v-else>--</span>
                  </span>

                </template>

              </a-table>
            </div>
            <div class="on_center_btn ">
              <a-button
                class="transferBtns"
                @click="onLeftlimit"
                type="primary"
                :disabled=!leftSelectedRowKeys.length
              >
                <a-icon
                  type="right"
                  style="margin-left: -4px;"
                />
              </a-button>
              <a-button
                class="transferBtns"
                @click="onRightlimit"
                type="primary"
                :disabled=!rightSelectedRowKeys.length
              >
                <a-icon
                  type="left"
                  style="margin-left: -4px;"
                />
              </a-button>
            </div>
            <div class="on_right_table">
              <a-table
                :pagination="false"
                :columns="proOrser.leftColumns"
                :data-source="proOrser.isproOrser==2? proOrser.productMockData_right:proOrser.servceMockData_right"
                :scroll="{y:482}"
                size="small"
                :rowKey="(record, index) => {return record.key}"
                :row-selection="{ selectedRowKeys: rightSelectedRowKeys, onChange: onRightSelectChange }"
              >
                <template
                  slot="_0"
                  slot-scope="action, record"
                >
                  <span v-if="['guest','third_guest'].includes(record.goods_type)">客装</span>
                  <span v-else-if="['shop','third_shop'].includes(record.goods_type)">店装</span>
                  <span v-else-if="record.goods_type== 'service'">服务</span>
                  <span v-else-if="record.goods_type=='third_service'">合作服务</span>
                  <span v-else-if="record.goods_type=='stored_card'">储值卡</span>
                  <span v-else-if="['rights_card','third_rights_card'].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <template
                  slot="brand_name"
                  slot-scope="action, record"
                >
                  <span v-if="record.brand_name">
                    {{record.brand_name}}
                  </span>
                  <span v-else>--</span>

                </template>
                <template
                  slot="goods_attr_value"
                  slot-scope="action, record"
                >
                  <span v-if="record.goods_attr_value">
                    {{record.goods_attr_value}}
                  </span>
                  <span v-else>--</span>

                </template>
                <template
                  slot="goods_name"
                  slot-scope="action, record"
                >
                  <span v-if="!record.goods_name">--</span>
                  <a-tooltip v-else>
                    <template slot="title">
                      <div class=" ">
                        <p class=" "> {{record.goods_name}}</p>
                        <p class=" ">商品码： {{ record.goods_bn }}</p>
                      </div>
                    </template>
                    <div class="goodsNamebox">
                      <p class="pro_name"> {{record.goods_name}}</p>
                      <p class="pro_bn">商品码： {{ record.goods_bn }}</p>
                    </div>
                  </a-tooltip>

                </template>
                <template
                  slot="_1"
                  slot-scope="action, record"
                >
                  <span v-if="record.finance_category_name || record.goods_category_name || record.store_category_name">
                    <a-tooltip>
                      <template slot="
              title">
                        {{record.finance_category_name}}
                        <span v-if="record.goods_category_name">,{{record.goods_category_name}}</span>
                        <span v-if="record.store_category_name">,{{record.store_category_name}}</span>
                      </template>
                      {{ record.finance_category_name}}
                    </a-tooltip> </span>
                  <span v-else>--</span>
                </template>
              </a-table>
            </div>
          </div>
          <!-- <div class="flex">
            <div
              class="transferPro_tip"
              style="width:608px;"
            >
              <span class="transferPro_tip_po"></span>全部产品
              <span class="transferPro_tip_sp">共{{proOrser.isproOrser==1?proOrser.servceMockData.length-proOrser.targetKeys.length:proOrser.productMockData.length-proOrser.targetKeys.length}}条</span>
            </div>
            <div class="transferPro_tip">
              <span class="transferPro_tip_po"></span>已选产品
              <span class="transferPro_tip_sp">共{{proOrser.targetKeys.length}}条</span>
            </div>
          </div>
          <a-transfer
            :data-source="proOrser.isproOrser==1?proOrser.servceMockData:proOrser.productMockData"
            :target-keys="proOrser.targetKeys"
            :show-select-all="false"
            @change="onlimitsChange2"
            class="trans-table"
          >
            <template
              slot="children"
              slot-scope="{ props: { direction, filteredItems, selectedKeys, disabled: listDisabled }, on: { itemSelectAll, itemSelect }, }"
            >
              <a-table
                :pagination="false"
                :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                :columns="proOrser.leftColumns"
                :data-source="filteredItems"
                :scroll="{y:522}"
                size="small"
                :style="{ pointerEvents: listDisabled ? 'none' : null }"
              >
                <template
                  slot="_0"
                  slot-scope="action, record"
                >
                  <span v-if="[1,3].includes(record.goods_type)">客装</span>
                  <span v-else-if="[4,2].includes(record.goods_type)">店装</span>
                  <span v-else-if="record.goods_type==5">服务</span>
                  <span v-else-if="record.goods_type==6">合作服务</span>
                  <span v-else-if="record.goods_type==7">储值卡</span>
                  <span v-else-if="[8,9].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <template
                  slot="_3"
                  slot-scope="action, record"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{record.goods_bn}}
                    </template>
                    {{ record.goods_bn || '--'}}
                  </a-tooltip>
                </template>
                <template
                  slot="goods_name"
                  slot-scope="action, record"
                >
                  <span v-if="!record.goods_name">--</span>
                  <a-tooltip v-else>
                    <template slot="title">
                      <div class=" ">
                        <p class=" "> {{record.goods_name}}</p>
                        <p class=" ">商品码： {{ record.goods_bn }}</p>
                      </div>
                    </template>
                    <div class="goodsNamebox">
                      <p class="pro_name"> {{record.goods_name}}</p>
                      <p class="pro_bn">商品码： {{ record.goods_bn }}</p>
                    </div>
                  </a-tooltip>  
                </template>
                <template
                  slot="_1"
                  slot-scope="action, record"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{record.finance_category_name}}
                      <span v-if="record.goods_category_name">,{{record.goods_category_name}}</span>
                      <span v-if="record.store_category_name">,{{record.store_category_name}}</span>
                    </template>
                    {{ record.finance_category_name}}
                  </a-tooltip>
                </template>
              </a-table>
            </template>
          </a-transfer> -->
        </div>
      </div>
    </a-modal>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import { pinyin } from 'pinyin-pro';
import {
  GetmanageGoodslist,
  Upcardswithproduct,//商品包含规格选择接口
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoodsProposal, UpdateCustomProposal, goodsInfoProposal, GetgoodsbrandsoptionsList
} from '@/api/commodity'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import ComposeInput from '@/components/compose_input'
import difference from 'lodash/difference';
const leftTableColumns2 = [
  {
    dataIndex: 'goods_name',
    ellipsis: true,
    title: '服务名称',
    key: '_2',
    width: 130,
    className: 'wid-94',
    scopedSlots: { customRender: '_2' },
  },
  {
    title: '标准单价',
    ellipsis: true,
    className: 'wid-94',
    dataIndex: 'goods_price',
  },
  {
    dataIndex: 'goods_attr_value',
    ellipsis: true,
    title: '商品规格',
    key: 'goods_attr_value',
    scopedSlots: { customRender: 'goods_attr_value' },
  },
  {
    ellipsis: true,
    title: '分类',
    dataIndex: 'finance_category_name',
    key: '_1',
    className: 'wid-94',
    scopedSlots: { customRender: '_1' },
  },
  {
    ellipsis: true,
    title: '品牌',
    dataIndex: 'brand_name',
    key: 'brand_name',
    scopedSlots: { customRender: 'brand_name' },

  },
];
const leftTableColumns = [
  {
    dataIndex: 'goods_name',
    // ellipsis: true,//
    title: '产品名称',
    // slots: { title: '产品名称' }, 
    // className: 'wid-94',
    width: 130,
    key: 'goods_name',
    scopedSlots: { customRender: 'goods_name' },
  },
  {
    dataIndex: 'goods_attr_value',
    ellipsis: true,
    title: '商品规格',
    key: 'goods_attr_value',
    scopedSlots: { customRender: 'goods_attr_value' },
  },
  {
    title: '标准单价',
    ellipsis: true,
    className: 'wid-94',
    dataIndex: 'goods_price',
  },
  {
    title: '类别',
    dataIndex: 'goods_type',
    ellipsis: true,
    className: 'wid-94',
    key: '_0',
    scopedSlots: { customRender: '_0' },
  },
  {
    ellipsis: true,
    title: '分类',
    className: 'wid-94',
    dataIndex: 'finance_category_name',
    key: '_1',
    scopedSlots: { customRender: '_1' },
  },
  {
    ellipsis: true,
    title: '品牌',
    dataIndex: 'brand_name',
    key: 'brand_name',
    scopedSlots: { customRender: 'brand_name' },
  },
];
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, ComposeInput },
  data() {
    return {
      config,
      isGoodsShows: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      selectArr4: [
        { name: '商品名称', id: '1', key: '1' },
        { name: '商品码', id: '2', key: '2' },
      ],
      plan_add: {
        columns: [
          {
            title: '组合',
            dataIndex: 'isGroup',
            key: '_0',
            scopedSlots: { customRender: '_0' },
            width: 22,
            customRender: (text, row, index) => {
              const obj = {
                children: (row.group_name || row.group_id) ? '组合' : ' ',
                attrs: {}
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.group_name || record.group_id) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#F6F9F9',

                  },
                  class: "guigeTableSpec_th0"
                };
              }
            }
          },
          {
            title: '名称',
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '标准单价',
            dataIndex: 'goods_price',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '类型',
            dataIndex: 'goods_type',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '最多消耗量',
            dataIndex: 'goods_limit_count',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '数量',
            dataIndex: 'group_limit_count',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
            customRender: (text, row, index) => {
              let children = (<div><a-switch class="mgr-10" vOn:change={this.onChangeQuan} checked-children="不限" un-checked-children="限制" v-model={row.group_limit_status} />
                <a-input-number class='ant-input_1' v-show={!row.group_limit_status} vOn:change={this.onChangeQuan} v-model={row.group_limit_count} min={0} /></div>)
              let children1 = (<div> <a-input-number class='ant-input_1' vOn:change={this.onChangeQuan} v-model={row.group_limit_count} min={0} /></div>)
              let children2 = (<div><a-switch vOn:change={this.onChangeQuan} class="mgr-10" checked-children="不限" un-checked-children="限制" v-model={row.goods_limit_status} />
                <a-input-number class='ant-input_1' v-show={!row.goods_limit_status} vOn:change={this.onChangeQuan} v-model={row.goods_limit_count} min={0} /></div>)
              let children3 = (<div><a-input-number class='ant-input_1' vOn:change={this.onChangeQuan} v-model={row.goods_limit_count} min={0} /></div>)
              const obj = {
                children: row.group_name ? this.isGoodsShows ? children : children1 : this.isGoodsShows ? children2 : children3,
                attrs: {}
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.rowSpan > 1) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#fff',
                  },
                  class: "guigeTableSpec_th1",
                };
              }
            }
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: '_7',
            width: 268,
            scopedSlots: { customRender: '_7' },
            customRender: (text, row, index) => {
              let children = (<div><a-button size="small" class="mgr-12 smallbtn" vOn:click={(e) => this.addRules(row, 3)}>{'编辑'}</a-button>
                <a-button size="small" class="mgr-12 smallbtn" vOn:click={(e) => this.handleBatch(row, index)}>{'删除'}</a-button></div>
              )
              let children2 = (<div><a-button size="small" class="mgr-12 smallbtn" vOn:click={(e) => this.handleBatch(row, index)}>{'删除'}</a-button></div>
              )
              const obj = {
                children: row.group_name ? children : children2,
                attrs: {}
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.rowSpan > 1) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#fff',
                  },
                  class: "guigeTableSpec_th1",
                };
              }
            }
          },
        ],
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      myArray: [],
      treeData: [],
      treeData2: [],
      treeData3: [],
      selectreeObj: {
        children: 'children',
        label: 'category_name',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        label: 'category_name',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        label: 'category_name',
        value: 'store_category_id'
      },
      brandsList: [],//品牌列表
      isactive: false,
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],

      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      form: {//表单数据
        goods_id: undefined,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        finance_category_id: undefined,
        store_category_id: undefined,
        is_give: 0,
        product_status: 1,
        proposal_count_price: '',//实耗成本
        goods_shou_type: 1,
        is_show: 1,
        goods_shou_end_time: '',
        proposal_every_sku_count: '', //单次兑换限兑商品数
        proposal_every_sku_type: 0,   //单次兑换是否限兑商品数：0不限、1限制
        proposal_sku_count: '',       //限兑总商品数
        proposal_sku_type: 0,         //是否限总商品数：0不限、1限制
        proposal_week_use_count: '',  //每周限兑次数
        proposal_week_use_type: 0,    //每周是否限兑次数：0不限、1限制
        proposal_month_use_count: '', //每月限兑次数
        proposal_month_use_type: 0,   //每月是否限兑次数：0不限、1限制
        proposal_use_count: '',       //限兑次数
        proposal_use_type: 0,         //是否限兑付次数：0不限、1限制
        proposal_pay_count: '',       //限购次数
        proposal_pay_type: 0,         //是否限购：0不限、1限购
        validity_type: 365,
        validity: 1,
        proposal_validity_type: 1,    //卡项有效期类型：1永久、2限时
        proposal_charging_type: 1,    //卡项计费类型：1购买日开始计费、2首次消耗开始计费
        proposal_validity: '',        //卡项有效期(天)
        goods_attr_val: [{            //商品规格值
          product_attr: undefined,  //规格值
          product_is_def: 1,        //默认货品：0非默认、1默认
          product_image: undefined,        //货品头图
          product_price: undefined,        //货品售价
          product_cost_price: undefined,   //货品成本价
          product_market_price: undefined, //货品市场价
          product_stock: undefined,        //货品库存
          product_bn: undefined
        }],
        goods_content: undefined,      //商品详情
        is_show: '',            //状态：0下架、1上架
        goods_category: undefined,     //商品分类
        goods_brand: undefined,        //商品品牌
        goods_supplier: 1,      //商品供应商：1总公司
        goods_type: '',          //商品类型：1客装实物、2店装实物、3服务商品、4储值卡、5权益卡 
        goods_slider: [],        //商品轮播图
        goods_image: undefined,         //商品头图
        goods_desc: undefined,          //商品简介
        goods_name: '',           //商品名称
        goods_bn: '',             //商品编号
      },
      step: 1,
      imgflag: false,
      pic_url_loading: false,
      // 产品或服务 穿梭框内容
      group_name: '',
      proOrser: {
        productPage: 1,
        servcePage: 1,
        servceTotal: 0,
        productTotal: 0,
        productMockData: [],
        servceMockData: [],
        servceMockData_right: [],
        productMockData_right: [],
        visible: false,
        isproOrser: 2,//服务 产品
        singleOrGroup: 1,//产品服务 1  组2
        selectedRowKeys: [],
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
      },
      leftSelectedRowKeys: [],//左边穿梭框的选中key
      rightSelectedRowKeys: [],//右侧侧
      isShowRight: true,
      isShowLeft: true,
      totalConsumption: 0,
    };
  },
  created() {
    this.getCompanyoptionList()
    this.getCompanyoptionList2()
    this.getCompanyoptionList3()
    this.getBrands()
    if (this.$route.query.type == 1) {
      this.addorupdateSpecFlag = 'add'
    }
    if (this.$route.query.type == 3) {
      this.addorupdateSpecFlag = 'update'
      this.openDatails()
    }
  },
  watch: {
    'form.validity_type': {
      handler(val) {
        console.log('val :>> ', val);
        if (val == 2) {
          this.form.proposal_validity_type = 2;
          this.form.proposal_validity = '';
        } else {
          this.form.proposal_validity_type = 1;
          this.form.proposal_validity = 1;
        }
      },
    },
    'form.validity': {
      handler(val) {
        console.log('val :>> ', val);
        this.form.proposal_validity = val * this.form.validity_type;
        console.log('this.form.proposal_validity :>> ', this.form.proposal_validity);
      },
    },
    'proOrser.isproOrser': {
      handler(val) {
        console.log('val :>> ', val);
        this.proOrser.leftColumns = val == 2 ? leftTableColumns : leftTableColumns2;
        if (val == 1) {
          this.loadServce()
        } else {
          this.loadProduct()
        }
        console.log('this.proOrser.selectedRowKeys :>> ', this.proOrser.selectedRowKeys);
        console.log('this.proOrser.targetKeys :>> ', this.proOrser.targetKeys);
      },
      deep: true
    },
    leftSelectedRowKeys: {
      handler: function (val) {
        console.log(this.proOrser.productMockData_right);
        this.proOrser.productMockData = [...this.proOrser.productMockData]
        this.proOrser.servceMockData = [...this.proOrser.servceMockData]
        this.$forceUpdate()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    moment,
    onChangeQuan(e) {
      let that = this;
      console.log('e :>> ', e);
      that.table.loading = true;
      console.log(that.table.tableData);
      // 判断是什么情况下
      let num = 0
      if (typeof (e) == 'number') {
        that.table.tableData.forEach(el => {
          if (el.group_name || el.group_id) {
            if (el.group_limit_count) num = num + el.group_limit_count
          } else {
            if (el.goods_limit_count) num = num + el.goods_limit_count
          }
        })
      }
      that.totalConsumption = num
      setTimeout(function () {
        that.table.loading = false;
        console.log('加载框已关闭...')
      }, 50);

    },
    // 分组
    sortGroup(sortData, str) {
      const groupBy = (array, f) => {
        let groups = {};
        array.forEach((o) => {
          let group = JSON.stringify(f(o));
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return Object.keys(groups).map((group) => {
          return groups[group];
        });
      };
      const sorted = groupBy(sortData, (item) => {
        return item[str]; // 返回需要分组的对象
      });
      return sorted;
    },
    // 设置每一行的rowSpan
    setRowSpan(data, i) {
      //保存上一个name
      var x = "";
      //相同name出现的次数
      var count = 0;
      //该name第一次出现的位置
      var startindex = 0;

      for (var i = 0; i < data.length; i++) {
        //这里是合并name列，根据 情况 自己完善
        var val = data[(i)].group_id ? data[(i)].group_id : data[(i)].group_name
        // var val = data[(i)].group_id;
        // var val = data[(i)].group_name;
        if (val) {
          if (i == 0) {
            x = val;
            count = 1;
            this.myArray[0] = 1
          } else {
            if (val == x) {
              count++;
              this.myArray[startindex] = count;
              this.myArray[i] = 0
            } else {
              count = 1;
              x = val;
              startindex = i;
              this.myArray[i] = 1
            }
          }
        }

      }

      console.log(this.myArray, '/ this.myArray');
    },
    submitProOrser() {
      let lengths = this.proOrser.isproOrser == 2 ? this.proOrser.productMockData_right.length : this.proOrser.servceMockData_right.length
      let targetList = this.proOrser.isproOrser == 2 ? this.proOrser.productMockData_right : this.proOrser.servceMockData_right
      let mockList = this.proOrser.isproOrser == 2 ? this.proOrser.productMockData : this.proOrser.servceMockData
      if (!lengths) return this.$notification.warning({ message: '请选择你要添加的产品/服务' });
      const uniqueId = this.proOrser.singleOrGroup == 2 ? 'id-' + this.table.tableData.length + 1 : ''
      let list = []
      let newMockList = []
      mockList.forEach(el => {
        if (el.children && el.children.length) {
          el.children.forEach(m => {
            newMockList.push(m)
          })
        }
      })
      targetList.forEach(val => {
        let idStr = newMockList.find(v => v.product_id == val.product_id)
        if (idStr) {
          list.push(idStr)
        }
      });
      let arr = list.map(val => {
        val.group_name = this.group_name ? this.group_name : uniqueId;
        val.isEditor = false;
        val.isGroup = true;
        val.goods_limit_status = false;
        val.goods_limit_count = undefined;
        val.group_limit_status = false;
        val.group_limit_count = undefined;
        return val
      })
      if (this.group_name) {//编辑
        var arr1 = this.table.tableData,
          new_arr = []
        arr1.forEach(item => (item.group_name != this.group_name && new_arr.push(item)))
        this.table.tableData = [...new_arr, ...arr]
      } else {
        this.table.tableData = [...this.table.tableData, ...arr]
      }
      setTimeout(() => {
        console.log(this.table.tableData);
        let arr1 = document.querySelectorAll('.guigeTableSpec_th0')
        let arr2 = document.querySelectorAll('.guigeTableSpec_th')
        arr1.forEach(el => {
          el.nextElementSibling.firstChild.style.display = "inline-block"
          el.nextElementSibling.firstChild.style.paddingLeft = '13px'

          console.log(el.parentNode.childNodes);
          // el.parentNode.childNodes[5].style.borderBottom == "thick solid #0000FF";
          // // el.parentNode.childNodes[5].style.borderBottom = '1px solid #E8EDEC'
          // el.parentNode.childNodes[6].style.borderBottom = '1px solid #E8EDEC'
          // // el.parentNode
        })

        arr2.forEach((el, idx) => {
          for (let i = 0; i < el.children.length; i++) {
            if (i < 5) {
              el.children[i].style.borderBottom = 'none'
            }

          }
          if (!el.firstChild.className) {
            if (arr2[idx + 1] && arr2[idx + 1].firstChild.className) {
              for (let i = 0; i < el.children.length; i++) {
                el.children[i].style.borderBottom = '1px solid #E8EDEC'
              }
            }
          } else {
            if (arr2[idx + 1] && arr2[idx + 1].firstChild.className) {
              for (let i = 0; i < el.children.length; i++) {
                el.children[i].style.borderBottom = '1px solid #E8EDEC'
              }
            } else if (!arr2[idx + 1]) {
              for (let i = 0; i < el.children.length; i++) {
                el.children[i].style.borderBottom = '1px solid #E8EDEC'
              }
            } else if (arr2[idx + 1] && !arr2[idx + 1].firstChild.className) {
              for (let i = 0; i < el.children.length; i++) {
                if (i < 5) {
                  el.children[i].style.borderBottom = 'none'
                }
              }
            }
          }



        })


      })
      this.setRowSpan(this.table.tableData, '1');//
      console.log(this.myArray, '<<, this.myArray');
      this.proOrser.visible = false;
    },
    handleBatch(item, index) {
      console.log('item :>> ', item);
      console.log('this.table.tableData :>> ', this.table.tableData);
      let that = this;
      let arr = [];
      if (item.group_name) {
        that.table.tableData.forEach(val => {
          console.log('val.group_name :>> ', val.group_name);
          if (val.group_name && val.group_name == item.group_name) {
            arr.push(val.goods_id)
          }
        })
      } else {
        arr.push(item.goods_id)
      }

      console.log('arr :>> ', arr);
      that.table.loading = true;
      arr.forEach(val => {
        let i = that.table.tableData.findIndex(v => v.goods_id == val)
        if (i != -1) {
          that.table.tableData.splice(i, 1)
        }
      })
      that.table.loading = false;
      console.log('this.table.tableData :>> ', this.table.tableData);
    },
    addRules(item, index) {
      console.log('item :>> ', item);
      let list = [];
      let ids = []
      this.table.tableData.forEach(val => {
        if (val.group_name == item.group_name) {
          list.push(val)
          ids.push(val.goods_id.toString())
        }
      });
      this.group_name = item.group_name;
      this.proOrser = {
        productPage: 1,
        servcePage: 1,
        servceTotal: 0,
        productTotal: 0,
        productMockData: [],
        servceMockData: [],
        visible: false,
        isproOrser: 2,//服务 产品
        singleOrGroup: 2,
        selectedRowKeys: [],
        mockData: [],
        targetKeys: ids,
        leftColumns: leftTableColumns,
        servceMockData_right: [],
        productMockData_right: [],
      }
      this.proOrser.visible = true
    },

    onlimitsChange2(nextTargetKeys, direction, s) {
      console.log('nextTargetKeys :>> ', nextTargetKeys);
      console.log(direction)
      console.log(s);
      this.proOrser.targetKeys = nextTargetKeys;
    },
    getRowClassname(record) {
      if (record.isGroup != null) {
        console.log(record.isGroup, "isGroup");
        return 'guigeTableSpec_th'
      }
    },
    // 机构搜索框
    getListSearchlimits(e) {
      console.log(e);
      let list = this.proOrser.isproOrser == 1 ? this.proOrser.servceMockData : this.proOrser.productMockData
      let str = '';
      if (e.activeStatus1.id == 1) {//商品名称
        str = 'goods_name'
      }
      if (e.activeStatus1.id == 2) {//商品码
        str = 'goods_bn'
      }
      let arr = []
      list.forEach((el, idx) => {
        if (el[str].includes(e.value)) {
          arr.push(el)
          list.splice(idx, 1);
        }
      })
      list = arr.concat(list)
      if (this.proOrser.isproOrser == 1) {
        this.proOrser.servceMockData = list;
      }
      if (this.proOrser.isproOrser == 2) {
        this.proOrser.productMockData = list;
      }
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    openDatails() {
      goodsInfoProposal({ goods_id: this.$route.query.id }).then(res => {
        this.form = Object.assign(this.form, res.data)
        let str = res.data.goods_bn
        this.goods_name = this.form.goods_name
        this.form.goods_type = this.form.goods_type + ''
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)

        if (this.form.goods_category) {
          this.form.goods_category = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.category_id + '')).split(',')

        } else if (this.form.goods_category == 0) {
          this.form.goods_category = undefined
        }
        if (this.form.store_category_id) {
          this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')

        } else if (this.form.store_category_id == 0) {
          this.form.store_category_id = undefined
        }

        if (this.form.finance_category_id) { this.form.finance_category_id = [res.data.finance_category_id + ""] } else {
          this.form.finance_category_id = undefined
        }



        console.log(this.form.store_category_id);
        // this.form.store_category_id
        // 
        let goods_list = this.form.product_list;
        let groupList = this.form.group_list
        // let goods_list = this.form.goods_list;
        goods_list.forEach((el, idx) => {
          groupList.forEach((m, n) => {
            if (el.group_id == m.proposal_group_id) {
              goods_list[idx].group_limit_count = m.group_limit_count
            }
          })
        })
        let arr = [];
        goods_list.forEach(val => {
          let obj = {
            ...val,
            goods_name: val.goods_name,
            group_name: val.group_id || '',
            group_limit_status: val.group_limit_status == 1 ? true : false,
            goods_limit_status: val.goods_limit_status == 1 ? true : false,
          }
          if (!obj.group_id) {
            obj.group_limit_count = obj.goods_limit_count
          }
          arr.push(obj)
        })
        // 匹配组 group_limit_count
        this.table.tableData = arr;
        this.setRowSpan(this.table.tableData, '2');

        console.log('this.table.tableData :>> ', this.table.tableData);
        let fileList = []
        // 回显轮播图
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        // 回显bn
        this.form.goods_bn = str
        // 回显 组合 
        this.$forceUpdate()
      })
    },
    //品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
        }
      })
    },
    //  分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData = res.data
        console.log(this.treeData, '>>>this.coptionsList');
      })
    },
    //  财务分类 树形列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.treeData2');
      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id')
        this.treeData3 = res.data
        console.log(this.treeData, '>>>this.coptionsList');
      })
    },
    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children, str);
      })
    },
    handleChange2(info) {
      console.log('info :>> ', info);
      if (info.file.status === 'uploading') {
        this.pic_url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_image = response?.data?.url
        this.pic_url_loading = false
      }
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    onStep(key) {
      if (key == 2) {
        this.form.goods_type = this.form.goods_type_hz == 0 ? 'rights_card' : 'third_rights_card';
        if (!this.form.goods_name) return this.$message.warning('请填写卡项名称！')
        if (!this.form.goods_bn) return this.$message.warning('请填写商品码！')
        if (!this.form.validity) return this.$message.warning('请填写有效期！')
        if (!this.form.finance_category_id) return this.$message.warning('请选择财务分类！')
        if (this.form.proposal_use_type == 1 && !this.form.proposal_use_count) return this.$message.warning('请填写兑付次数！')
        if (!this.form.proposal_count_price && this.form.proposal_use_type == 0) return this.$message.warning('请填写实耗成本！')
        if (this.form.proposal_month_use_type == 1 && !this.form.proposal_month_use_count) return this.$message.warning('请填写每月兑付！')
        if (this.form.proposal_week_use_type == 1 && !this.form.proposal_week_use_count) return this.$message.warning('请填写每周兑付！')
        if (this.form.proposal_every_sku_type == 1 && !this.form.proposal_every_sku_count) return this.$message.warning('请填写单次兑付！')
        if (this.form.proposal_pay_type == 1 && !this.form.proposal_pay_count) return this.$message.warning('请填写限购数量！')

        if (!this.form.validity) return this.$message.warning('请填写兑付次数！')
        if (!this.form.validity) return this.$message.warning('请填写每月兑付！')
        if (!this.form.goods_attr_val[0].product_price) {
          return this.$message.warning('请填写标准单价！')
        }
        // if (!this.form.goods_brand) return this.$message.warning('请选择品牌！')
        // if (!this.form.goods_category) return this.$message.warning('请选择门店分类！')
        // if (!this.form.store_category_id) return this.$message.warning('请选择商城分类！')
        if (this.form.goods_shou_type == 2 && !this.form.goods_shou_end_time) return this.$message.warning('请填写截止日期！')
        // form.proposal_month_use_type==1  form.proposal_week_use_type==1
        // form.proposal_month_use_count 月 form.proposal_week_use_count 周
        // 有月 有周 月大于周   
        if (this.form.proposal_month_use_type == 1 && this.form.proposal_week_use_type == 1) {
          let proflag = true
          if (this.form.proposal_month_use_count < this.form.proposal_week_use_count) {
            proflag = false
          }
          if (!proflag) {
            return this.$message.warning('周兑付次数应不大于月对付次数！')
          }
        }



        this.step = key
      }
      if (key == 3) {
        if (!this.table.tableData.length) {
          return this.$message.warning('请添加卡项内容！')
        }
        this.step = key


      }
      if (key == 4) {
        this.step = key
        delete this.form.goods_type_hz
      }
      this.step = key


    },
    //取消
    cancel(key) {
      if (key == 1) {
        this.changeRoute()
      } else {
        let self = this
        self.$confirm({
          icon: 'exclamation-circle',
          closable: true,
          title: '提示',
          content: '当前已填写数据尚未保存,确定取消保存吗?',
          okText: '确定',
          closable: true,
          cancelText: '取消',
          onOk() {
            self.changeRoute()
          },
          onCancel() {
          },
        });
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$forceUpdate();
    },
    getPopupContainer(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })
      return document.querySelectorAll('.styledatas')[0]
    },
    getPopupContainer2(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas2')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })
      return document.querySelectorAll('.styledatas2')[0]
    },
    getPopupContainer3(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas3')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })
      return document.querySelectorAll('.styledatas3')[0]
    },

    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {
      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },

    changeMenuplan(e) {
      console.log(e);
      this.proOrser = {
        productPage: 1,
        servcePage: 1,
        servceTotal: 0,
        productTotal: 0,
        productMockData: [],
        servceMockData: [],
        visible: false,
        isproOrser: 2,//服务 产品
        singleOrGroup: 1,//产品服务 1  组2
        selectedRowKeys: [],
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
        servceMockData_right: [],
        productMockData_right: [],
      }
      this.group_name = '';
      this.proOrser.visible = true
      this.proOrser.singleOrGroup = e.key
      // this.loadProduct() 
      // this.table.tableData
      // this.proOrser.mockData
    },
    // 提交按钮
    okclickmod(e) {
      console.log('this.table.tableData :>> ', this.table.tableData);
      let group_list = [];
      let goods_list = [];
      let arr = [];
      this.table.tableData.forEach(val => {
        if (val.group_name) {
          arr.push({
            group_name: val.group_name,
            goods_id: val.goods_id,
            product_id: val.product_id,
            group_limit_status: val.group_limit_status ? 1 : 0,
            group_limit_count: val.group_limit_count,
            group_count_price: val.goods_price,
            goods_limit_status: val.goods_limit_status ? 1 : 0,
            goods_limit_count: val.goods_limit_count
          })
        } else {
          let obj = {
            group_name: val.group_name,
            goods_id: val.goods_id,
            product_id: val.product_id,
            goods_limit_status: val.goods_limit_status ? 1 : 0,
            goods_limit_count: val.goods_limit_count,
            proposal_count_price: val.goods_price,
          }
          goods_list.push(obj)
        }
      })

      function getList(list) {
        const map = new Map()
        list.forEach((item, index, arr) => {
          if (!map.has(item.group_name)) {
            map.set(
              item.group_name,
              arr.filter(a => a.group_name == item.group_name)
            )
          }
        })
        return Array.from(map).map(item => [...item[1]])
      }
      const list = getList(arr)
      console.log(list, arr, '>> ,list arr');
      list.forEach(v => {
        let obj = {
          product_list: [],
          group_limit_status: this.isGoodsShows ? 1 : 0,
          group_limit_count: '',
          group_count_price: '',
          group_limit_status: '',
        }
        v.forEach(s => {
          // obj.goods_list.push(s.goods_id) 

          if (s.group_limit_count) {
            // obj.group_limit_status = this.isGoodsShows ? 1 : s.group_limit_status;
            // obj.group_limit_count = s.group_limit_count;
            // obj.group_count_price = s.group_count_price;
            // obj.goods_limit_status = s.goods_limit_status;

            obj.group_limit_status = this.isGoodsShows ? 1 : s.group_limit_status;
            obj.group_limit_count = s.group_limit_count;
            obj.group_count_price = s.group_count_price;
            obj.group_limit_status = s.goods_limit_status;
            s.goods_count_price = s.group_count_price
            s.goods_limit_count = s.goods_limit_count;
            s.goods_count_price = s.group_count_price;
            s.goods_limit_status = s.goods_limit_status;
          }
          delete s.group_limit_count;
          delete s.group_count_price;
          delete s.group_limit_status
          obj.product_list.push(s)
        })
        group_list.push(obj)
      })
      delete this.goodSpecsSingletableData[0].product_id
      // this.form.goods_attr_val = this.goodSpecsSingletableData;
      this.form.goods_attr_val[0].product_bn = this.form.goods_bn;
      let data = {
        ...this.form,
        group_list: group_list,
        product_list: goods_list,
      }

      delete data.goods_type_hz
      if (this.form.finance_category_id && this.form.finance_category_id.length) {
        data.finance_category_id = this.form.finance_category_id.length ? this.form.finance_category_id[0] : ''
      }
      if (this.form.goods_category && this.form.goods_category.length) {
        data.goods_category = this.form.goods_category.length ? this.form.goods_category.pop() + '' : undefined
      } else {
        data.goods_category = 0
      }
      if (this.form.store_category_id && this.form.store_category_id.length) {
        data.store_category_id = this.form.store_category_id.length ? this.form.store_category_id.pop() + '' : undefined
      } else {
        data.store_category_id = 0
      }
      delete data.pic_url_loading
      // 区分新增 修改接口
      if (this.$route.query.type == 3) {
        if (data.category_info) delete data.category_info
        if (data.store_category_info) delete data.store_category_info
        if (data.created_at) delete data.created_at
        if (data.updated_at) delete data.updated_at
        if (data.finance_category_info) delete data.finance_category_info
        UpdateCustomProposal(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success('编辑成功')
            // this.getList()
            this.changeRoute()
          }
        })
      } else {
        //新增 
        delete data.goods_id
        delete data.product_id
        delete data.product_id_add
        delete data.goods_type_hz
        CreateGoodsProposal(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success('创建成功')
            // this.getList()
            this.changeRoute()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    onChangeTimes(date, dateString) {
      this.form.goods_shou_end_time = dateString
    },

    // 左边列表选中
    onLeftSelectChange(selectedRowKeys) {
      // 
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.leftSelectedRowKeys = selectedRowKeys;

    },
    onRightSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.rightSelectedRowKeys = selectedRowKeys;
      // console.log('selectedRowKeys changed: ', this.rightSelectedRowKeys.length);
    },
    onLeftlimit() {
      // 判断是 proOrser.isproOrser==1 服务  2 商品
      // 筛选 左边的原始数据 给右边 proOrser.productMockData_right 
      if (this.proOrser.isproOrser == 1) {
        this.mockServeRecursion(this.proOrser.servceMockData, this.leftSelectedRowKeys)
        console.log(this.proOrser.servceMockData);
        this.$forceUpdate()
        this.leftSelectedRowKeys = []
        this.proOrser.servceMockData = [...this.proOrser.servceMockData]
        this.proOrser.servceMockData_right = [...this.proOrser.servceMockData_right]
        console.log(this.proOrser.servceMockData_right, '/this.proOrser.productMockData_righ ');

      }
      if (this.proOrser.isproOrser == 2) {
        this.mockRecursion(this.proOrser.productMockData, this.leftSelectedRowKeys)
        console.log(this.proOrser.productMockData);
        this.$forceUpdate()
        this.leftSelectedRowKeys = []
        this.proOrser.productMockData = [...this.proOrser.productMockData]
        this.proOrser.productMockData_right = [...this.proOrser.productMockData_right]
        console.log(this.proOrser.productMockData_right, '/this.proOrser.productMockData_righ ');

      }
    },
    onRightlimit() {
      let that = this
      // productMockData  proOrser.productMockData_right  servceMockData_right  servceMockData 
      //  proOrser.isproOrser==1 服务  2 商品
      if (that.proOrser.isproOrser == 1) {
        that.mockdisabledRecursion(that.proOrser.servceMockData, that.rightSelectedRowKeys)
        let newarr = []
        that.proOrser.servceMockData_right.forEach((el, idx) => {
          if (!that.rightSelectedRowKeys.includes(el.key)) {
            newarr.push(el)
          }

        })
        that.proOrser.servceMockData_right = newarr
        that.rightSelectedRowKeys = []
        that.$forceUpdate()
        that.proOrser.servceMockData = [...that.proOrser.servceMockData]
        that.proOrser.servceMockData_right = [...that.proOrser.servceMockData_right]

      }
      if (that.proOrser.isproOrser == 2) {
        that.mockdisabledRecursion(that.proOrser.productMockData, that.rightSelectedRowKeys)
        let newarr = []
        that.proOrser.productMockData_right.forEach((el, idx) => {
          if (!that.rightSelectedRowKeys.includes(el.key)) {
            newarr.push(el)
          }

        })
        that.proOrser.productMockData_right = newarr
        that.rightSelectedRowKeys = []
        that.$forceUpdate()
        that.proOrser.productMockData = [...that.proOrser.productMockData]
        that.proOrser.productMockData_right = [...that.proOrser.productMockData_right]

      }

    },
    getCheckboxPropsFn(record) {
      return ({
        props: {
          disabled: record.disabled,
          name: record.goods_name,
        }
      })
    },
    // 递归改变id key number
    mockRecursion(data, str) {
      let that = this
      data.forEach(el => {
        if (str.includes(el.key)) {
          el.disabled = true
          that.proOrser.productMockData_right.push({ ...el, children: undefined, disabled: false })
        }
        if (el.children && el.children.length) this.mockRecursion(el.children, str);

      })
    },
    // 递归改变id key number
    mockServeRecursion(data, str) {
      let that = this
      data.forEach(el => {
        if (str.includes(el.key)) {
          el.disabled = true
          that.proOrser.servceMockData_right.push({ ...el, children: undefined, disabled: false })
        }
        if (el.children && el.children.length) that.mockServeRecursion(el.children, str);

      })
    },
    // 递归改变 disabled
    mockdisabledRecursion(data, str) {
      data.forEach(el => {
        if (str.includes(el.key)) {
          el.disabled = false
        }
        if (el.children && el.children.length) this.mockdisabledRecursion(el.children, str);

      })
    },
    // 
    expandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {//有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <i class='meiye-icon meiye-xiangxia' ></i>{" "}
              {/* <a-icon type="down" />{" "} */}
            </a>
          );
        } else {//有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <i class='meiye-icon meiye-xiangyou' ></i>
              {/* <a-icon type="right" /> */}
            </a>
          );
        }
      } else {//无数据-图标
        return (
          <span style="margin-right:0px">
          </span>
        );
      }
    },


    async loadProduct() {
      let that = this;
      let data = {
        filter: {
          goods_type: ['guest', 'third_guest', 'shop', 'third_shop']
        },
        limit: 100,
        page: that.proOrser.productPage
      }
      Upcardswithproduct(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        that.proOrser.productTotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            if (el.children && el.children.length) {
              el.disabled = true
              if (el.children.length == 1 && el.children[0].product_attr.length == 0) {
                el.children[0].goods_attr_value = '单规格'
              }
              el.children.forEach((m, n) => {
                m.ischild = true
                m.key = m.goods_id + '-' + m.product_id
                m.title = ''
                m.disabled = false
                m.goods_price = m.product_price
                m.goods_bn = el.goods_bn
                m.goods_name = el.goods_name
                m.brand_name = el.brand_name
                m.goods_type = el.goods_type
                m.finance_category_name = el.finance_category_name
                m.goods_category_name = el.goods_category_name
                m.store_category_name = el.store_category_name
                if (Object.getPrototypeOf(m.product_attr) === Object.prototype) {
                  m.goods_attr_value = Object.values(m.product_attr).join(',')
                }
              })

            } else {
              el.disabled = false
            }
            that.proOrser.productMockData.push(
              { ...el, key: el.goods_id.toString(), title: el.goods_name }
            )
          })
          that.proOrser.productPage++;
          that.loadProduct();
        } else {
          if (that.proOrser.singleOrGroup == 1) {
            let arr = that.table.tableData.filter(el => { return el.product_id })
            that.proOrser.productMockData.forEach(el => {
              if (arr.includes(el.product_id)) {
                el.disabled = true
              }
            })
          }
          console.log(that.proOrser.productMockData, "------------productMockData");//全部门店或者商城数据
          that.proOrser.mockData = [...that.proOrser.servceMockData, ...that.proOrser.productMockData]
        }
      })
    },
    async loadServce() {
      let that = this;
      let data = {
        filter: {
          goods_type: ['service', 'third_service']
        },
        limit: 100,
        page: that.proOrser.servcePage
      }
      Upcardswithproduct(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        that.proOrser.servceTotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            if (el.children && el.children.length) {
              el.disabled = true
              if (el.children.length == 1 && el.children[0].product_attr.length == 0) {
                el.children[0].goods_attr_value = '单规格'
              }

              el.children.forEach((m, n) => {
                m.ischild = true
                m.key = m.goods_id + '-' + m.product_id
                m.title = ''
                m.disabled = false
                m.goods_price = m.product_price
                m.goods_bn = el.goods_bn
                m.goods_name = el.goods_name
                m.brand_name = el.brand_name
                m.goods_type = el.goods_type
                m.finance_category_name = el.finance_category_name
                m.goods_category_name = el.goods_category_name
                m.store_category_name = el.store_category_name
                if (Object.getPrototypeOf(m.product_attr) === Object.prototype) {
                  m.goods_attr_value = Object.values(m.product_attr).join(',')
                }
              })


            } else {
              el.disabled = false
            }
            that.proOrser.servceMockData.push(
              { ...el, key: el.goods_id.toString(), title: el.goods_name }
            )
          })
          that.proOrser.servcePage++;
          that.loadServce();
        } else {
          if (that.proOrser.singleOrGroup == 1) {
            let arr = that.table.tableData.filter(el => { return el.product_id })
            that.proOrser.servceMockData.forEach(el => {
              if (arr.includes(el.product_id)) {
                el.disabled = true
              }
            })
          }
          console.log(that.proOrser.mockData, "------------servceMockData");//全部门店或者商城数据
          this.proOrser.mockData = [...this.proOrser.servceMockData, ...this.proOrser.productMockData]
        }
      })
    },
  },
  computed: {
    goods_name: {
      get() {
        return this.form.goods_name
      },
      set(val) {
        this.form.goods_name = val
        this.form.goods_bn = ''
        let a = pinyin(val, { toneType: 'none' })
        let b = a.split(' ')
        let arr = []
        b.forEach(el => {
          arr.push(el.slice(0, 1))
        })
        arr = arr.join('')
        this.form.goods_bn = arr
      },
    },
    rowSelection() {
      let that = this
      return {
        selectedRowKeys: that.leftSelectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          that.leftSelectedRowKeys = selectedRowKeys;
          // that.getRowSelection({ disabled: false })
        },
        // hideDefaultSelections: true,
        getCheckboxProps: record => ({
          props: {
            disabled: record.disabled
          }
        }),
      };
    },
  }
};
</script>
<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}
.transferBox {
  position: relative;
  /deep/.ant-transfer-list-header {
    display: none !important;
  }
  /deep/ .ant-transfer-list {
    padding-top: 13px !important;
  }
}
.transferPro {
  .transferProTable {
    border: 1px solid #ccdada;
    border-right: none;
  }

  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0 0px 0 0 !important;
  }
}

.transferPro_tip {
  text-align: left;
  color: @fontColor4;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  display: inline-block;
}
.transferPro_tip_sp {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #9ea6a4;
  line-height: 22px;
}
.transferPro_tip_po {
  display: inline-block;
  width: 3px;
  margin-right: 9px;
  margin-bottom: 1px;
  height: 10px;
  background: @primaryColor;
  border-radius: 2px;
}
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 12px 13px;
  flex: 1;
  position: relative;
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      margin-left: 33px;
    }
    .active {
      background: @stepAbgColor;
    }
    .base {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: @stepDfontColor;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .active1 {
      color: @stepAfontColor;
      font-size: 14px;
      font-weight: 600;
    }
    .line {
      width: 1.5px;
      height: 32px;
      background: @steplineColor;
      margin: 0 5px 6px 40px;
    }
  }
  .addright {
    margin-left: 66px;
    // padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    overflow: auto;
    /deep/.ant-col-4 {
      width: 136px !important;
    }
    .zengpinstep {
      padding: 31px 0 26px 28px;
      background: #fff;
      overflow: hidden;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 4px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 19px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 6px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 25px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 20px;
      }
    }
    .one {
      height: calc(100vh - 132px);
    }
    .two {
      height: calc(100vh - 132px);
    }
    .three {
      height: calc(100vh - 135px);
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #131010;
      line-height: 22px;
      // margin-bottom: 14px;
      .xing {
        color: #f35e67;
        font-weight: 500;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: flex-start;
    }
    .three-btns {
      padding-left: 50px;
      padding-bottom: 20px;
      margin-left: 2px;
      // position: fixed;
      // bottom: 23px;
      background: #ffffff;
      // padding-top: 20px;
      width: 83%;
      z-index: 10;
      // border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 15px;
      font-weight: 400;
      color: #819190;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}
/deep/.ant-form-item-control,
/deep/.ant-form-item-control-wrapper {
  position: static !important;
}
/deep/ .ant-form-item-children {
  position: static !important;
}
.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 222px;
    position: relative;
  }
}
.samerow3 {
  /deep/ .ant-form-item-label-left {
    margin-left: 156px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 206px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items2 {
      width: 264px;
      text-align: left;
      display: inline-block;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    .tabelBody_items {
      width: 206px;
      text-align: left;
    }
  }
}
/deep/ .avatar-uploader .ant-upload {
  width: 48px !important;
  height: 48px !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: #2d3835;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: #2d3835;
      margin-right: 10px;
      width: 64px;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  background: #fff;
}
.validity {
  /deep/ .ant-input {
    height: 32px;
  }
}
.validity2 {
  /deep/ .ant-input {
    height: 32px;
    width: 180px;
  }
}
/deep/.ant-table-tbody > tr:last-child td {
  border-bottom: 1px solid rgb(232, 237, 236) !important;
}
.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
/deep/.ant-upload.ant-upload-select-picture-card {
  background: #fff;
}
/deep/ .anticon.anticon-info-circle {
  color: @fontColor5;
}
/deep/ .guigeTable {
  position: relative;
  &::after {
    content: " ";
    width: 100%;
    height: 2px;
    background: #e8edec;
    position: absolute;
    top: -4px;
    left: 0;
  }
}
/deep/.ant-btn.smallbtn {
  height: 28px;
}
.mgt-40 {
  margin-top: 40px;
}
.po-relative {
  position: relative;
}
.table-btn-clk-plan {
  height: 36px;
  line-height: 36px;
  display: inline-block;
  box-shadow: 0px 1px 0px 0px @btnMainoutDColor,
    0px 2px 4px 0px rgba(201, 155, 157, 0.1),
    inset 0px -2px 0px 0px @btnMainInsertDColor !important;
  border-radius: 4px 0 0 4px;
  // background: @btnMainbgDColor;
  color: @btnMainfontDColor;
  background: @primaryColor;
  border: none;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;

  > span {
    padding-bottom: 1px;
  }

  &:focus,
  &:active {
    // background: @btnMainbgFColor !important;
    background: #0ab0a4 !important;
    color: @btnMainfontFColor !important;
    border: none;
    box-shadow: 0px 1px 0px 0px @btnMainoutHColor,
      0px 2px 4px 0px rgba(201, 155, 157, 0.1), inset 0px -2px 0px 0px #04968e !important;
  }

  &:hover {
    color: @btnMainfontHColor;
    // background: @btnMainbgHColor;
    background: #0abfb1;
    border: none;
    box-shadow: 0px 1px 0px 0px @btnMainoutHColor,
      0px 2px 4px 0px rgba(201, 155, 157, 0.1),
      inset 0px -2px 0px 0px @btnMainInsertHColor;
  }
}
.table-btn-clk-plan2 {
  border-radius: 0px 4px 4px 0;
}
.table-btn-clk-plan3 {
  width: 1px;
  height: 36px;
  line-height: 36px;
  border-radius: 0px;
  border-radius: 0px;
  display: flex;
  align-items: center;

  .table-btn-clk-plan-line {
    width: 1px;
    height: 16px;
    background: #ffffff;
    opacity: 0.6;
    display: inline-block;
  }
}
.pdt-20 {
  padding-top: 20px;
}
.footer-bn {
  width: 100%;
  border-top: 1px solid #d8eceb;
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th {
  &:hover {
    .guigeTableSpec_th0 {
      color: @baseColor11 !important;
    }
  }
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th .guigeTableSpec_th0 {
  color: @baseColor11 !important;
}
.pro_name {
  display: inline-block;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
  line-height: 20px;
}
.goodsNamebox {
  width: 90px;
  height: 36px;
  display: flex;
  flex-direction: column;
}
.pro_bn {
  display: inline-block;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor5;
  line-height: 17px;
}
.on_right_table,
.on_left_table {
  width: 627px;
  height: 530px;
  background: rgba(216, 216, 216, 0);
  border-radius: 4px;
  border: 1px solid #ccdada;
  background: #fff;
  /deep/ .ant-table-placeholder {
    border: none;
    border-radius: 0;
    margin-top: 0;
  }
  /deep/ .ant-table-small {
    border: none;
  }
  /deep/ .ant-table-thead > tr > th {
    border: none !important;
    height: 45px;
    line-height: 45px;
    background: #ebf7f7 !important;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ccdada !important;
    box-shadow: none;
    padding-top: 0px !important;
    padding-bottom: 0 !important;
    font-size: 14px;
    color: @fontColor4;
    font-weight: 500;
  }
  /deep/ .ant-table-tbody > tr > td {
    height: 64px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden;
  }
}
.on_center_btn {
  width: 78px;
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // line-height: 530px;
  // transferBtns ant-btn ant-btn-primary
  .transferBtns.ant-btn-primary {
    width: 36px;
    height: 36px;
    background: #0bc7b9;
    box-shadow: 0px 2px 4px 0px rgba(49, 150, 129, 0.4),
      inset 0px -2px 0px 0px #04a59c;
    border-radius: 4px;
    // background: #ffffff;
    margin-bottom: 12px;
    // box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    //   inset 0px -3px 0px 0px #dbeae8;
    // border-radius: 4px;
    // border: 1px solid #bacbc7;
    // margin-bottom: 12px;
    // color: #414041;
  }
  .transferBtns.ant-btn-primary[disabled] {
    width: 36px;
    height: 36px;
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
      inset 0px -3px 0px 0px #dbeae8;
    border-radius: 4px;
    border: 1px solid #bacbc7;
    margin-bottom: 12px;
    color: #414041;
  }
}
/deep/.ant-table-content .ant-table-header {
  overflow: hidden;
}
.mgr-6 {
  margin-right: 6px;
}
.mgr-10 {
  margin-right: 10px;
}
.guigeTablebox td :td ~ td {
  color: red;
}
.mgt-2 {
  margin-top: -2px;
}
</style>
